.col {
  .p-accordion {

    .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
      box-shadow: none;

    }

    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
    .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
      background-color: var(--dragcategory-hover-background-color);
      border-color: var(--dragcategory-border-color);
      color: var(--dragcategory-text-color);
    }

    .p-accordion-header .p-accordion-header-link {
      border: none;
      background-color: var(--dragcategory-background-color);
      border-bottom: 1px var(--dragcategory-border-color) solid;
    }

    p-accordiontab {
      &:first-child .p-accordion-header .p-accordion-header-link {
        border-radius: 0 !important;
      }

      &:last-child .p-accordion-header .p-accordion-header-link {
        border-radius: 0 !important;
      }
    }

    .p-accordion-content {
      border: none;
      background-color: var(--dragcategory-hover-background-color);
    }

    .drag-drawflow:hover .catColor{
      color: var(--white);
    }

    .draggable-- {

      &drawboard_entrypoints {
        .p-accordion-header-link{
          pointer-events: none;
        }
        .catColor {
          color: var(--element-entrypoint-text-color);
        }
        .p-disabled .catColor{
          color: var(--element-entrypoint-disabled-text-color);
        }
        .drag-drawflow {
          border-color: var(--element-entrypoint-background-color);

          &:hover {
            background-color: var(--element-entrypoint-background-color);
          }
        }
      }

      &drawboard_wait {
        .catColor {
          color: var(--element-wait-background-color);
        }

        .drag-drawflow {
          border-color: var(--element-wait-background-color);

          &:hover {
            background-color: var(--element-wait-background-color);
          }
        }
      }

      &drawboard_actions {
        .catColor {
          color: var(--element-action-background-color);
        }

        .drag-drawflow {
          border-color: var(--element-action-background-color);

          &:hover {
            background-color: var(--element-action-background-color);
          }
        }
      }

      &drawboard_conditions {
        .catColor {
          color: var(--element-condition-background-color);
        }

        .drag-drawflow {
          border-color: var(--element-condition-background-color);

          &:hover {
            background-color: var(--element-condition-background-color);
          }
        }
      }

      &drawboard_triggers {
        .catColor {
          color: var(--element-trigger-background-color);
        }

        .drag-drawflow {
          border-color: var(--element-trigger-background-color);

          &:hover {
            background-color: var(--element-trigger-background-color);
          }
        }
      }
    }
  }

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--dragarea-scrollbar-handle-color) var(--dragarea-scrollbar-background-color);;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--dragarea-scrollbar-handle-color);
    border-radius: 20px;
    border: 3px solid var(--dragarea-scrollbar-background-color);
  }


}
@media only screen and (max-width: 1023px) {
  .col {
    .p-accordion .p-accordion-header .p-accordion-header-link{
      overflow-x: hidden;
      text-wrap: nowrap !important;

      .mx-3.catColor{
        width: 0;
        overflow: hidden;
      }
    }
    .p-accordion-content {
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.5s;

      .drag-drawflow {
        /*display: none;*/
        text-wrap: nowrap;
        overflow-x: hidden;
        visibility: hidden;
      }

    }

    &:hover{
      .p-accordion .p-accordion-header .p-accordion-header-link{
        .mx-3.catColor{
          width: auto;
        }
      }
      .p-accordion-content {
        height: auto;
        padding: 1.25rem;

        .drag-drawflow, {
          /*display: block;*/
          width: auto;
          height: auto;
          visibility: visible;
        }
      }
    }

  }
}
