@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap');

/* FONT */
:root {
  --font-size-default: 14px;
  --font-family-default: Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

html {
  font-size: var(--font-size-default);
  color: var(--text-color);
}

body, .p-component {
  /*font-family: 'Open Sans', sans-serif;*/
  line-height: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-weight: 300;
  font-size: 4rem;
}

h2 {
  font-weight: 700;
  font-size: 2.5rem;
}

h3 {
  font-weight: 700;
  font-size: 1.5rem;

}

h4 {
  font-weight: 700;
  font-size: 1.25rem;
}

h5 {
  font-weight: 500;
  font-size: 1rem;
}

h6 {
  font-weight: 500;
  font-size: .75rem;
}

.subtitle {
  font-weight: normal;
  font-size: 1.5rem;
  letter-spacing: -2%
}

small {
  font-weight: normal;
  font-size: .875rem
}

.pretitle {
  font-weight: normal;
  font-size: .75rem;
  letter-spacing: 1px;
  color: $neutral;
}

a {
  color: var(--color-link);
  cursor: pointer;

  &:hover {
    color: var(--color-link-hover);
    text-decoration: none;
  }
}

b, strong {
  font-weight: 600;
}

.text-danger{
  color: var(--red-500);
}

@media (max-width: $xs_max_width) {
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
  }
  h3 {
    font-size: 1.125rem;
    letter-spacing: unset;
  }
}

