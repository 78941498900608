/* WEBHOOKS - add tag - START */

.webhooks--add_tag{
  flex-direction: row-reverse;

  &-component{
    display: flex;
    justify-content: end;
  }

  &.p-dropdown {
    background: transparent;
    border: none;
    transition: none;
    border-radius: 0;
    color: var(--color-link);

    &:hover{
      color: var(--color-link-hover);
    }

  }
  &.p-focus{
    box-shadow: none;
  }
  .p-placeholder,
  .p-dropdown-label {
    font-style: normal;
    box-shadow: none;
    padding: .2rem;
    font-size: 0.875rem;
    color: inherit;
  }
  .p-dropdown-trigger{
    width: auto;
    padding: .2rem 0 .2rem .2rem;
    font-size: 0.875rem;
    color: inherit;
  }
}
.webhooks--tag_overlay{
  left: unset;
  right: 20px;
  width: 300px;
}

/* WEBHOOKS - add tag - END */
