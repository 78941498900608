@mixin word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin position-sticky($top, $bottom) {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: $top;
  bottom: $bottom;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }
  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin position-middle {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-absolute($ptop, $pright, $pbottom, $left,$zindex) {
  position: absolute;
  top: $ptop;
  right: $pright;
  bottom: $pbottom;
  left: $left;
  z-index: $zindex;
}

//MENU LEFT
@mixin menu-left($display, $margin-container, $position, $overflow, $height, $width, $width-hover, $margin, $position-account, $bottom-account ) {
  display: flex;
  flex-direction: row;
  .sidebar__menu {
    display: $display;
    position: $position;
    height: $height;
    width: $width;
    overflow: $overflow;
    z-index: 1100;
    flex-shrink: 0;
    @include transition-menu(width, $tra_standard);

    &.sidebar__hovered {
      width: $width-hover !important;
      box-shadow: 4px 0 8px rgba(0, 0, 0, 0.25);

      .sidebar__menu-account {
        @include transition-menu(all, $tra_standard);
        margin-left: .5rem;
      }
    }

    .sidebar__menu-account {
      position: $position-account;
      bottom: $bottom-account;
    }
  }
  .main-container {
    //flex-shrink: 0;
    width: calc(100% - $margin-container);

    main {
      margin-left: $margin;
    }
  }
  footer {
    margin-left: $margin;
    width: calc(100% - $margin);
  }
}

//TRANSITION
@mixin transition-max-height {
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition: max-height .5s #{.65 + $i * .02}s ease-in;
    }
  }
}

@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}

/*
@mixin badgestyle($color) {
  color: rgba($color, 1);
  &:after {
    background: rgba($color,  1)
  }
}
*/
// DEVICES ///
// Small devices (landscape phones, 576px and up)
@mixin media-xs {
  @media (min-width: $xs_min_width) {
    @content;
  }
}

@mixin media-maxxs {
  @media (max-width: $xs_max_width) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin media-s {
  @media (min-width: $s_min_width) {
    @content;
  }
}

@mixin media-maxs {
  @media (max-width: $s_max_width) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin media-m {
  @media (min-width: $m_min_width) {
    @content;
  }
}

@mixin media-maxm {
  @media (max-width: $m_max_width) {
    @content;
  }
}

// Extra large devices (large desktops, 1200px and up)
@mixin media-l {
  @media (min-width: $l_min_width) {
    @content;
  }
}

@mixin media-maxl {
  @media (max-width: $l_max_width) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: $xl_min_width) {
    @content;
  }
}

@mixin media-xxl {
  @media (min-width: $xxl_min_width) {
    @content;
  }
}

@mixin media-maxh {
  @media screen and (max-height: $m_max_height) and (min-width: $m_min_width) {
    @content;
  }
}

@mixin media-hxl {
  @media (min-device-height: $xl_min_height) and (min-device-width: $xl_min_width) {
    @content;
  }
}

/* --- TRANSITION ---*/
@mixin transition-menu($type, $duration) {
  transition: $type $duration;
}

/* --- FILTER AREA ---*/
@mixin filter-area($height) {
  box-shadow: 1px 1px 6px 0 rgb(32 33 36 / 28%);
  background: $neutralWhite;
  top: unset !important;
  left: 0 !important;
  bottom: 0 !important;
  height: $height !important;
  position: fixed !important;
  padding: 0;
  width: 100%;
  overflow: auto;
  //width: calc(100vw - 1rem)!important;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  &-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    z-index: 1000;
    height: 3.75rem;
    padding: 12px 1rem;
    background: $neutralWhite;
    box-shadow: 1px 1px 6px 0 rgb(32 33 36 / 28%);
  }
;
}

