.p-dialog-content {
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--node-settings-scrollbar-handle-color) var(--node-settings-scrollbar-background-color);;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--node-settings-scrollbar-handle-color);
    border-radius: 20px;
    border: 3px solid var(--node-settings-scrollbar-background-color);
  }
}
