/*-- Global Style --*/
$primaryDark: #004277;
$primary: #005293;
$primaryLight: #007BDD;
$primaryLighter: #2196F3;
$secondaryDark: #004277;
$secondary: #8AC7F9;
$secondaryLight: #F3F7FF;
$secondaryLighter: #FCFCFC;
$neutralBlack: #000000;
$neutralDarkest: #242427;
$neutralDarker: #48484F;
$neutralDark: #6C6C76;
$neutral: #85858d;
$neutralLight: #B5B5BE;
$neutralLighter: #DADADE;
$neutralLightest: #F8F9FA;
$neutralWhite: #FFFFFF;
$total: #d8d8d8;
$positive: #689F38;
$positiveDark: #5e8f32;
$info: #0BAB94;
$alertLight: #fcecbd;
$alert: #E8C14F;
$alertDark: #ea9a00;
$alertDarker: #DE9200;
$warning: #C62828;
$accentLight: #EB5B87;
$accent: #A37FBF;
$current: #0090C2;

/*--- Screen Sizes ---*/

$xs_min_width: 576px;
$xs_max_width: 767px;
$s_min_width: 768px;
$s_max_width: 991px; //958px;
$m_min_width: 992px; //959px
$m_max_width: 1199px;
$m_max_height: 840px;
$l_min_width: 1200px;
$l_max_width: 1440px;
$l_min_height: 841px;
$xl_min_width: 1441px;
$xxl_min_width: 1900px;
$xl_min_height: 1080px;

/* TRANSITIONS */

$tra_standard: .2s;

:root {

  --red-500: #ef4444;

  --font-size-default: 14px;
  --font-famiy-default: Roboto, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --font-famiy: Roboto, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  --color-link: var(--blue-500);
  --color-link-hover: var(--blue-800);

  --left-col-width: 300px;

  --error-color: var(--red-500);

  --btn-action-background-color: var(--fl-var-action-med);
  --btn-action-border-color: var(--fl-var-action-med);
  --btn-action-hover-background-color: var(--fl-var-action-high);
  --btn-action-hover-border-color: var(--fl-var-action-high);
  --btn-action-disabled-background-color: var(--fl-var-action-low);
  --btn-action-disabled-border-color: var(--fl-var-action-low);
  --btn-action-outline-hover-background-color: var(--fl-var-action-med);

  --btn-function-background-color: var(--fl-var-function-med);
  --btn-function-border-color: var(--fl-var-function-med);
  --btn-function-hover-background-color: var(--fl-var-function-high);
  --btn-function-hover-border-color: var(--fl-var-function-high);
  --btn-function-disabled-background-color: var(--fl-var-function-low);
  --btn-function-disabled-border-color: var(--fl-var-function-low);
  --btn-function-outline-hover-background-color: var(--fl-var-function-med);

  --header-background-color: var(--fl-var-bkg-med);
  --header-text-color: var(--white);
  --header-height: 3.5rem;

  --dragarea-background-color: var(--fl-var-bkg-high);
  --dragarea-scrollbar-background-color: var(--fl-var-bkg-high);
  --dragarea-scrollbar-handle-color: var(--gray-500);
  --dragcategory-background-color: var(--fl-var-bkg-high);
  --dragcategory-border-color: var(--fl-var-bkg-med);
  --dragcategory-text-color: var(--white);
  --dragcategory-hover-background-color: var(--fl-var-bkg-med);
  --dragelement-background-color: var(--fl-var-bkg-high);
  --dragelement-border-color: var(--fl-var-bkg-low);
  --dragelement-text-color: var(--white);
  --dragelement-hover-background-color: var(--fl-var-bkg-low);
  --dragelement-hover-border-color: var(--fl-var-bkg-low);

  --drawarea-background-color: var(--gray-50);
  --drawarea-grid-color: var(--gray-50);
  --drawarea-drag-inner-shadow-color: var(--gray-400);
  --drawarea-drag-background-color: var(--gray-300);

  --drawarea-tools-background-color: var(--gray-200);
  --drawarea-tools-btn-background-color: var(--gray-100);
  --drawarea-tools-btn-hover-background-color: var(--white);
  --drawarea-tools-btn-text-color: var(--gray-600);

  --node-background-color: var(--white);
  --node-text-color: var(--bluegray-600);
  --node-strong-text-color: var(--bluegray-900);
  --node-border-color: var(--bluegray-100);
  --node-selected-background-color: var(--white);
  --node-selected-text-color: var(--bluegray-600);
  --node-selected-border-color: var(--neutral-500);
  --node-selected-shadow-color: var(--neutral-500);
  --node-title-background-color: var(--bluegray-100);
  --node-title-text-color: var(--fl-var-bkg-low);
  --node-invalid-ring-color: var(--orange-500);

  --node-template-placeholder-background: var(--gray-100);
  --node-template-placeholder-icon-color: var(--gray-500);

  --node-input-background-color: var(--white);
  --node-input-hover-background-color: var(--gray-300);
  --node-input-border-color: var(--gray-300);
  --node-output-background-color: var(--white);
  --node-output-hover-background-color: var(--gray-300);
  --node-output-border-color: var(--gray-300);

  --node-actions-buttons-hover-text-color: var(--white);

  --node-settings-scrollbar-background-color: var(--white);
  --node-settings-scrollbar-handle-color: var(--gray-300);

  --node-move-main-color: var(--green-600);
  --node-move-hover-background-color: var(--green-300);

  --edge-color: var(--gray-400);
  --edge-hover-color: var(--blue-200);
  --edge-selected-color: var(--blue-600);
  --edge-line-width: 1px;

  --element-entrypoint-text-color: var(--gray-100);
  --element-entrypoint-disabled-text-color: var(--gray-400);

  --element-entrypoint-background-color: var(--gray-500);
  --element-condition-background-color: var(--purple-300);
  --element-trigger-background-color: var(--cyan-400);
  --element-wait-background-color: var(--blue-400);
  --element-action-background-color: var(--green-500);

  --minimap-element-entrypoint-background-color: var(--gray-400);
  --minimap-element-condition-background-color: var(--purple-200);
  --minimap-element-trigger-background-color: var(--cyan-200);
  --minimap-element-wait-background-color: var(--blue-200);
  --minimap-element-action-background-color: var(--green-200);

  --minimap-mask-border-color: var(--red-300);
  --minimap-edge-color: var(--bluegray-400);

  --stats-in-area-bg-color: var(--gray-100);
  --stats-in-area-border-color: var(--gray-300);
  --stats-in-tag-border-color: var(--blue-500);
  --stats-in-tag-text-color: var(--blue-500);
  --stats-in-tag-bg-color: var(--white);
  --stats-in-tag-hover-bg-color: var(--blue-100);
  --stats-enter-tag-border-color: var(--gray-600);
  --stats-enter-tag-text-color: var(--gray-600);
  --stats-enter-tag-bg-color: var(--white);
  --stats-enter-tag-hover-bg-color: var(--gray-200);
  --stats-out-tag-border-color: var(--gray-600);
  --stats-out-tag-text-color: var(--gray-600);
  --stats-out-tag-bg-color: var(--white);
  --stats-out-tag-hover-bg-color: var(--gray-200);
  --stats-yes-tag-border-color: var(--green-600);
  --stats-yes-tag-text-color: var(--green-600);
  --stats-yes-tag-bg-color: var(--white);
  --stats-yes-tag-hover-bg-color: var(--green-100);
  --stats-no-tag-border-color: var(--red-500);
  --stats-no-tag-text-color: var(--red-500);
  --stats-no-tag-bg-color: var(--white);
  --stats-no-tag-hover-bg-color: var(--red-100);

  --stats-email-border-color: var(--gray-600);
  --stats-email-text-color: var(--gray-600);
  --stats-email-bg-color: var(--white);
  --stats-email-hover-bg-color: var(--gray-200);

  --form-placeholders-color: var(--gray-300);
  --form-labels-color: var(--gray-400);

  --tooltip-background-color: var(--neutral-700);
  --tooltip-text-color: var(--white);

}

/* PICMO */
.emoji-popup.picmo__light {
  --accent-color: var(--fl-var-function-med);
  --background-color: var(--white);
  --border-color: var(--neutral-300);
  --category-name-background-color: var(--white);
  --category-name-text-color: var(--neutral-700);
  --error-color: var(--red-500);
  --error-color-dark: var(--red-700);
  --focus-indicator-color: var(--neutral-300);
  --hover-background-color: var(--neutral-200);
  --placeholder-background-color: var(--neutral-200);
  --preview-background-color: var(--neutral-200);
  --search-background-color: var(--white);
  --search-focus-background-color: var(--white);
  --search-icon-color: var(--neutral-400);
  --search-placeholder-color: var(--neutral-400);
  --secondary-background-color: var(--neutral-100);
  --secondary-text-color: var(--neutral-2300);
  --tag-background-color: var(--neutral-200);
  --text-color: var(--neutral-700);
}
.picmo__picker.picmo__picker>* {
  --ui-font: var(--font-famiy-default);
}
