.p-button.p-button-action, .p-buttonset.p-button-action > .p-button, .p-splitbutton.p-button-action > .p-button {
  color: var(--white);
  background: var(--btn-action-background-color);
  border: 1px solid var(--btn-action-border-color);
}
.p-button.p-button-action:enabled:hover, .p-buttonset.p-button-action > .p-button:enabled:hover, .p-splitbutton.p-button-action > .p-button:enabled:hover,
span.p-button.p-button-action:hover, span.p-buttonset.p-button-action > .p-button:hover, span.p-splitbutton.p-button-action > .p-button:hover{
  background: var(--btn-action-hover-background-color);
  color: var(--white);
  border-color: var(--btn-action-hover-border-color);
}
.p-button.p-button-action:enabled:active, .p-buttonset.p-button-action > .p-button:enabled:active, .p-splitbutton.p-button-action > .p-button:enabled:active {
  background: var(--btn-action-hover-background-color);
  color: var(--white);
  border-color: var(--btn-action-hover-border-color);
}
.p-button.p-button-action:enabled:focus, .p-buttonset.p-button-action > .p-button:enabled:focus, .p-splitbutton.p-button-action > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem var(--btn-action-disabled-background-color);
}
.p-button.p-button-action:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--btn-action-border-color), 0 1px 2px #000;
}
.p-button.p-button-action.p-button-outlined{
  border-color: var(--btn-action-border-color);
  color: var(--btn-action-border-color);
  background-color: transparent;
}
.p-button.p-button-action.p-button-outlined:enabled:hover{
  color:var(--white);
  background-color: var(--btn-action-outline-hover-background-color);
  border-color: var(--btn-action-outline-hover-background-color);
}


.p-button.p-button-function, .p-buttonset.p-button-function > .p-button, .p-splitbutton.p-button-function > .p-button {
  color: var(--white);
  background: var(--btn-function-background-color);
  border: 1px solid var(--btn-function-border-color);
}
.p-button.p-button-function:enabled:hover, .p-buttonset.p-button-function > .p-button:enabled:hover, .p-splitbutton.p-button-function > .p-button:enabled:hover,
span.p-button.p-button-function:hover, span.p-buttonset.p-button-function > .p-button:hover, span.p-splitbutton.p-button-function > .p-button:hover{
  background: var(--btn-function-hover-background-color);
  color: var(--white);
  border-color: var(--btn-function-hover-border-color);
}
.p-button.p-button-function:enabled:active, .p-buttonset.p-button-function > .p-button:enabled:active, .p-splitbutton.p-button-function > .p-button:enabled:active {
  background: var(--btn-function-hover-background-color);
  color: var(--white);
  border-color: var(--btn-function-hover-border-color);
}
.p-button.p-button-function:enabled:focus, .p-buttonset.p-button-function > .p-button:enabled:focus, .p-splitbutton.p-button-function > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem var(--btn-function-disabled-background-color);
}
.p-button.p-button-function:enabled:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--btn-function-border-color), 0 1px 2px #0000;
}
.p-button.p-button-function.p-button-outlined, span.p-button.p-button-function.p-button-outlined,
.p-button.p-button-function.p-button-outlined:enabled, span.p-button.p-button-function.p-button-outlined:enabled{
  border-color: var(--btn-function-border-color);
  color: var(--btn-function-border-color);
  background-color: transparent;
}
.p-button.p-button-function.p-button-outlined:hover, span.p-button.p-button-function.p-button-outlined:hover,
.p-button.p-button-function.p-button-outlined:enabled:hover, span.p-button.p-button-function.p-button-outlined:enabled:hover{
  color:var(--white);
  background-color: var(--btn-function-outline-hover-background-color);
  border-color: var(--btn-function-outline-hover-background-color);
}
