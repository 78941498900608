:root {
  --white: #FFFFFF;
  --black: #000000;

  /*
  --gray-100: #F5F5F5;
  --gray-200: #EEEEEE;
  --gray-300: #E0E0E0;
  --gray-400: #BDBDBD;
  --gray-500: #9E9E9E;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  */

  --neutral-50: #FAFAFA;
  --neutral-100: #F5F5F5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-600: #525252;
  --neutral-700: #404040;
  --neutral-800: #262626;
  --neutral-900: #171717;

  /*
  --blue-50: #f4fafe;
  --blue-100: #cae6fc;
  --blue-200: #a0d2fa;
  --blue-300: #75bef8;
  --blue-400: #4baaf5;
  --blue-500: #2196f3;
  --blue-600: #1c80cf;
  --blue-700: #1769aa;
  --blue-800: #125386;
  --blue-900: #0d3c61;
  --green-50: #f6fbf6;
  --green-100: #d4ecd5;
  --green-200: #b2ddb4;
  --green-300: #90cd93;
  --green-400: #6ebe71;
  --green-500: #4caf50;
  --green-600: #419544;
  --green-700: #357b38;
  --green-800: #2a602c;
  --green-900: #1e4620;
  --yellow-50: #fffcf5;
  --yellow-100: #fef0cd;
  --yellow-200: #fde4a5;
  --yellow-300: #fdd87d;
  --yellow-400: #fccc55;
  --yellow-500: #fbc02d;
  --yellow-600: #d5a326;
  --yellow-700: #b08620;
  --yellow-800: #8a6a19;
  --yellow-900: #644d12;
  --cyan-50: #f2fcfd;
  --cyan-100: #c2eff5;
  --cyan-200: #91e2ed;
  --cyan-300: #61d5e4;
  --cyan-400: #30c9dc;
  --cyan-500: #00bcd4;
  --cyan-600: #00a0b4;
  --cyan-700: #008494;
  --cyan-800: #006775;
  --cyan-900: #004b55;
  --pink-50: #fef4f7;
  --pink-100: #fac9da;
  --pink-200: #f69ebc;
  --pink-300: #f1749e;
  --pink-400: #ed4981;
  --pink-500: #e91e63;
  --pink-600: #c61a54;
  --pink-700: #a31545;
  --pink-800: #801136;
  --pink-900: #5d0c28;
  --indigo-50: #f5f6fb;
  --indigo-100: #d1d5ed;
  --indigo-200: #acb4df;
  --indigo-300: #8893d1;
  --indigo-400: #6372c3;
  --indigo-500: #3f51b5;
  --indigo-600: #36459a;
  --indigo-700: #2c397f;
  --indigo-800: #232d64;
  --indigo-900: #192048;
  --teal-50: #f2faf9;
  --teal-100: #c2e6e2;
  --teal-200: #91d2cc;
  --teal-300: #61beb5;
  --teal-400: #30aa9f;
  --teal-500: #009688;
  --teal-600: #008074;
  --teal-700: #00695f;
  --teal-800: #00534b;
  --teal-900: #003c36;
  --orange-50: #fff8f2;
  --orange-100: #fde0c2;
  --orange-200: #fbc791;
  --orange-300: #f9ae61;
  --orange-400: #f79530;
  --orange-500: #f57c00;
  --orange-600: #d06900;
  --orange-700: #ac5700;
  --orange-800: #874400;
  --orange-900: #623200;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --purple-50: #faf4fb;
  --purple-100: #e7cbec;
  --purple-200: #d4a2dd;
  --purple-300: #c279ce;
  --purple-400: #af50bf;
  --purple-500: #9c27b0;
  --purple-600: #852196;
  --purple-700: #6d1b7b;
  --purple-800: #561561;
  --purple-900: #3e1046;
  --red-50: #fff5f5;
  --red-100: #ffd1ce;
  --red-200: #ffada7;
  --red-300: #ff8980;
  --red-400: #ff6459;
  --red-500: #ff4032;
  --red-600: #d9362b;
  --red-700: #b32d23;
  --red-800: #8c231c;
  --red-900: #661a14;
  --primary-50: #f4fafe;
  --primary-100: #cae6fc;
  --primary-200: #a0d2fa;
  --primary-300: #75bef8;
  --primary-400: #4baaf5;
  --primary-500: #2196f3;
  --primary-600: #1c80cf;
  --primary-700: #1769aa;
  --primary-800: #125386;
  --primary-900: #0d3c61;
  */

  --brown-50: #fdf8f6;
  --brown-100: #f2e8e5;
  --brown-200: #eaddd7;
  --brown-300: #e0cec7;
  --brown-400: #d2bab0;
  --brown-500: #bfa094;
  --brown-600: #a18072;
  --brown-700: #977669;
  --brown-800: #846358;
  --brown-900: #43302b;

  --fl-var-base: #fff;
  --fl-var-contrast: #000;
  --fl-var-txt-low: #efefef;
  --fl-var-txt-med: #a4a4a4;
  --fl-var-txt-high: #232323;
  --fl-var-bkg-low: #6b6b6b;
  --fl-var-bkg-med: #505050;
  --fl-var-bkg-high: #454545;
  --fl-var-scroll-low: #2c2c2c;
  --fl-var-scroll-med: #454545;
  --fl-var-scroll-high: #5a5a5a;
  --fl-var-info-low: #e9eff2;
  --fl-var-info-med: #92d2dd;
  --fl-var-info-high: #00bcdd;
  --fl-var-primary-low: #3399cc;
  --fl-var-primary-med: #0079c1;
  --fl-var-primary-high: #4b7fa0;
  --fl-var-element-low: #00c5ab;
  --fl-var-element-med: #00a08b;
  --fl-var-element-high: #588778;
  --fl-var-block-low: #f87676;
  --fl-var-block-med: #f85e5e;
  --fl-var-block-high: #e31c40;
  --fl-var-confirm-low: #43a047;
  --fl-var-confirm-med: #33691E;
  --fl-var-confirm-high: #1f3f12;
  --fl-var-action-lower: #ffe8dc;
  --fl-var-action-low: #ffc39b;
  --fl-var-action-med: #f47200;
  --fl-var-action-high: #8e4200;
  --fl-var-function-lower: #DDDDDD;
  --fl-var-function-low: #999999;
  --fl-var-function-med: #666666;
  --fl-var-function-high: #444444;

  /* TAILWIND */

  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: #f4f4f5;
  --surface-d: #e5e7eb;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #3f3f46;
  --text-color-secondary: #71717a;
  --primary-color: #4f46e5;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f4f4f5;
  --surface-200: #e4e4e7;
  --surface-300: #d4d4d8;
  --surface-400: #a1a1aa;
  --surface-500: #71717a;
  --surface-600: #52525b;
  --surface-700: #3f3f46;
  --surface-800: #27272a;
  --surface-900: #18181b;
  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;

  --blue-50:#f5f9ff;
  --blue-100:#d0e1fd;
  --blue-200:#abc9fb;
  --blue-300:#85b2f9;
  --blue-400:#609af8;
  --blue-500:#3b82f6;
  --blue-600:#326fd1;
  --blue-700:#295bac;
  --blue-800:#204887;
  --blue-900:#183462;
  --green-50:#f4fcf7;
  --green-100:#caf1d8;
  --green-200:#a0e6ba;
  --green-300:#76db9b;
  --green-400:#4cd07d;
  --green-500:#22c55e;
  --green-600:#1da750;
  --green-700:#188a42;
  --green-800:#136c34;
  --green-900:#0e4f26;
  --yellow-50:#fefbf3;
  --yellow-100:#faedc4;
  --yellow-200:#f6de95;
  --yellow-300:#f2d066;
  --yellow-400:#eec137;
  --yellow-500:#eab308;
  --yellow-600:#c79807;
  --yellow-700:#a47d06;
  --yellow-800:#816204;
  --yellow-900:#5e4803;
  --cyan-50:#f3fbfd;
  --cyan-100:#c3edf5;
  --cyan-200:#94e0ed;
  --cyan-300:#65d2e4;
  --cyan-400:#35c4dc;
  --cyan-500:#06b6d4;
  --cyan-600:#059bb4;
  --cyan-700:#047f94;
  --cyan-800:#036475;
  --cyan-900:#024955;
  --pink-50:#fef6fa;
  --pink-100:#fad3e7;
  --pink-200:#f7b0d3;
  --pink-300:#f38ec0;
  --pink-400:#f06bac;
  --pink-500:#ec4899;
  --pink-600:#c93d82;
  --pink-700:#a5326b;
  --pink-800:#822854;
  --pink-900:#5e1d3d;
  --indigo-50:#f7f7fe;
  --indigo-100:#dadafc;
  --indigo-200:#bcbdf9;
  --indigo-300:#9ea0f6;
  --indigo-400:#8183f4;
  --indigo-500:#6366f1;
  --indigo-600:#5457cd;
  --indigo-700:#4547a9;
  --indigo-800:#363885;
  --indigo-900:#282960;
  --teal-50:#f3fbfb;
  --teal-100:#c7eeea;
  --teal-200:#9ae0d9;
  --teal-300:#6dd3c8;
  --teal-400:#41c5b7;
  --teal-500:#14b8a6;
  --teal-600:#119c8d;
  --teal-700:#0e8174;
  --teal-800:#0b655b;
  --teal-900:#084a42;
  --orange-50:#fff8f3;
  --orange-100:#feddc7;
  --orange-200:#fcc39b;
  --orange-300:#fba86f;
  --orange-400:#fa8e42;
  --orange-500:#f97316;
  --orange-600:#d46213;
  --orange-700:#ae510f;
  --orange-800:#893f0c;
  --orange-900:#642e09;
  --bluegray-50:#f7f8f9;
  --bluegray-100:#dadee3;
  --bluegray-200:#bcc3cd;
  --bluegray-300:#9fa9b7;
  --bluegray-400:#818ea1;
  --bluegray-500:#64748b;
  --bluegray-600:#556376;
  --bluegray-700:#465161;
  --bluegray-800:#37404c;
  --bluegray-900:#282e38;
  --purple-50:#fbf7ff;
  --purple-100:#ead6fd;
  --purple-200:#dab6fc;
  --purple-300:#c996fa;
  --purple-400:#b975f9;
  --purple-500:#a855f7;
  --purple-600:#8f48d2;
  --purple-700:#763cad;
  --purple-800:#5c2f88;
  --purple-900:#432263;
  --red-50:#fef6f6;
  --red-100:#fbd2d2;
  --red-200:#f8afaf;
  --red-300:#f58b8b;
  --red-400:#f26868;
  --red-500:#ef4444;
  --red-600:#cb3a3a;
  --red-700:#a73030;
  --red-800:#832525;
  --red-900:#601b1b;
  --primary-50:#f6f6fe;
  --primary-100:#d5d3f9;
  --primary-200:#b3aff4;
  --primary-300:#928cef;
  --primary-400:#7069ea;
  --primary-500:#4f46e5;
  --primary-600:#433cc3;
  --primary-700:#3731a0;
  --primary-800:#2b277e;
  --primary-900:#201c5c;

}
