#drawflow{
  .drawflow-node.processing{
    pointer-events: none;

    .node--processing{
      display: flex;
    }

    &.selected{
      .actions{
        display: none;
      }
    }
  }
}

#drawflow, #minimap {
  /* Editing Drawflow */

  position: relative;
  cursor: move;
  width: 100%;
  height: 100%;
  background: var(--drawarea-background-color);

  .drawflow svg {
    z-index: 0;
    position: absolute;
    overflow: visible !important;
  }
}

#drawflow, #minimap, #flows-node-shadow {

  .connection {
    position: absolute;
    pointer-events: none;

    .main-path {
      stroke: var(--edge-color);
      stroke-width: var(--edge-line-width);
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      pointer-events: all;

      &.hovered {
        stroke: var(--edge-color);
        //stroke-width: 5px;
      }

      &:hover {
        stroke: var(--edge-color);
        cursor: pointer;
      }

      &.selected {
        stroke: var(--edge-color);
      }
    }

    .point {
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      stroke: var(--node-border-color);
      stroke-width: 1;
      fill: white;
      pointer-events: all;

      &.selected,
      &:hover {
        fill: #1266ab;
      }
    }
  }

  .drawflow-node, &.drawflow-node {

    border-color: transparent;
    background: transparent;
    min-height: unset;
    height: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 240px;
    color: var(--node-text-color);
    z-index: 2;
    padding: 0;

    .image-container{
      max-height: 225px;

      &-placeholder{
        background-color: var(--node-template-placeholder-background);
        color: var(--node-template-placeholder-icon-color);
      }
    }

    &.selected {
      .node-container {
        --tw-ring-offset-width: 2px;
        box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--drawarea-background-color),
                    var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--node-selected-border-color),
                    0 20px 13px rgb(0 0 0 / 0.05),
                    0 12px 6px rgb(0 0 0 / 0.1);
      }
    }


    .invalid-node {
      --tw-ring-offset-width: 2px;
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--drawarea-background-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--node-invalid-ring-color);
    }

    .node-content {
      strong {
        color: var(--node-strong-text-color);
      }

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .actions {
      display: none;
      right: -40px;
      width: 40px;

      &-left{
        right: unset;
        left: -40px;
      }

      span.p-button-outlined {
        transition: color .25s;
        background-color: var(--drawarea-background-color);

        &:hover {
          color: var(--node-actions-buttons-hover-text-color);
          background-color: var(--btn-function-outline-hover-background-color);

          &.p-button-danger{
            background-color: var(--red-500);
            border-color: var(--red-500);
          }

        }
      }
    }

    &:hover, &.selected {
      .actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }

    .inputs,
    .outputs {
      height: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      left: 0;

      .input,
      .output {
        border: 1px solid var(--node-input-border-color);
        display: block;
        background: var(--node-input-background-color);
        border-radius: 50%;
        z-index: 1;
        margin-bottom: 0;
        height: 10px;
        width: 10px;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        cursor: default;

        /*
        &:hover {
          background: var(--node-input-hover-background-color);
          border-color: var(--node-input-border-color);
        }

         */

      }

    }

    /*
        .input {
          cursor: n-resize;
        }

        .output {
          cursor: s-resize;
        }
    */
    .inputs {
      top: 0;
    }

    .outputs {
      bottom: 0;
    }

    .title-box {
      font-weight: normal;
      background: var(--node-title-background-color);
      color: var(--white);
    }

    .drawflow_content_node {
      width: 100%;
      display: block;

      .category_ {
        &entrypoint {
          .title-box {
            background-color: var(--element-entrypoint-background-color);
          }
        }

        &wait {
          .title-box {
            background-color: var(--element-wait-background-color);
          }
        }

        &action {
          .title-box {
            background-color: var(--element-action-background-color);
          }
        }

        &condition {
          .title-box {
            background-color: var(--element-condition-background-color);
          }
        }

        &trigger {
          .title-box {
            background-color: var(--element-trigger-background-color);
          }
        }
      }
    }

    .condition-box {
      display: flex;
      justify-content: space-around;
      align-content: center;

      div {
        width: 50%;
        padding: 0 0 1rem;
      }
    }

    &.selected {

      .title-box {
        background-color: var(--node-selected-shadow-color);
      }

    }

    .stats{
      &__view {
        cursor: move;

        .node-stats{
          background-color: var(--stats-in-area-bg-color);
          border-color: var(--stats-in-area-border-color);
        }

        .stats__details{
          transition: background-color ease-in-out .5s;
          background-color: var(--stats-email-bg-color);
          border: 1px solid var(--stats-email-border-color);
          color: var(--stats-email-text-color);
          &:hover{
            background-color: var(--stats-email-hover-bg-color);
          }

          strong{
            color: var(--stats-email-text-color);
          }
        }

        .p-tag{
          cursor: pointer;
          border-radius: .25rem;
          transition: background-color ease-in-out .5s;

          .p-tag-icon, .p-tag-value{
            font-size: 0.85rem;

          }
          &.stats{
            &__enter{
              background-color: var(--stats-enter-tag-bg-color);
              border: 1px solid var(--stats-enter-tag-border-color);
              color: var(--stats-enter-tag-text-color);
              &:hover{
                background-color: var(--stats-enter-tag-hover-bg-color);
              }
            }
            &__in{
              background-color: var(--stats-in-tag-bg-color);
              border: 1px solid var(--stats-in-tag-border-color);
              color: var(--stats-in-tag-text-color);
              &:hover{
                background-color: var(--stats-in-tag-hover-bg-color);
              }
            }
            &__out{
              background-color: var(--stats-out-tag-bg-color);
              border: 1px solid var(--stats-out-tag-border-color);
              color: var(--stats-out-tag-text-color);
              &:hover{
                background-color: var(--stats-out-tag-hover-bg-color);
              }
            }
            &__condition-yes{
              background-color: var(--stats-yes-tag-bg-color);
              border: 1px solid var(--stats-yes-tag-border-color);
              color: var(--stats-yes-tag-text-color);
              &:hover{
                background-color: var(--stats-yes-tag-hover-bg-color);
              }
            }
            &__condition-no{
              background-color: var(--stats-no-tag-bg-color);
              border: 1px solid var(--stats-no-tag-border-color);
              color: var(--stats-no-tag-text-color);
              &:hover{
                background-color: var(--stats-no-tag-hover-bg-color);
              }
            }
          }
        }
      }
    }
  }

  .drawflow {
    .drawflow-delete {
      position: absolute;
      display: block;
      width: 30px;
      height: 30px;
      background: var(--black);
      color: var(--white);
      z-index: 4;
      border: 2px solid var(--white);
      line-height: 30px;
      font-weight: bold;
      text-align: center;
      border-radius: 50%;
      font-family: monospace;
      cursor: pointer;
    }

    > .drawflow-delete {
      border: 1px solid var(--green-200);
      background: var(--white);
      color: var(--green-400);
      -webkit-box-shadow: 0 2px 20px 2px var(--green-100);
      box-shadow: 0 2px 20px 2px var(--green-100);
      margin-left: -15px;
      margin-top: 15px;
    }

  }

  .drawflow .drawflow-node:hover {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    /*
    @TODO check z-index
      z-index:3 !important;
      */
  }


  .drawflow .main-path {
    fill: none;
    stroke-width: 1px;
    stroke: var(--edge-color);

    &.connection-rect {
      stroke-width: 0;
      cursor: move;
      //pointer-events: none;
    }
  }

  .connection-exitnode {
    fill: var(--white);
    stroke-width: 1px;
    stroke: var(--bluegray-100);
  }

  .connection-exittext {
    font-size: 20px;
    fill: var(--bluegray-200);
    font-weight: 200;
  }

  .connection-dropin {
    display: none;
    fill: var(--green-600)
  }

  .plus-sign {
    display: none;
    stroke-width: 2px;
    stroke: var(--white);
  }


  &.on-dragging, &.on-clipboard {
    .connection-dropin {
      display: block;
    }

    &:has(.drawflow:empty) {
      background: var(--drawarea-drag-background-color) url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20style%3D%22fill%3A%20%23419544%22%20width%3D%2232px%22%20height%3D%2232px%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20d%3D%22M256%20512A256%20256%200%201%200%20256%200a256%20256%200%201%200%200%20512zM232%20344V280H168c-13.3%200-24-10.7-24-24s10.7-24%2024-24h64V168c0-13.3%2010.7-24%2024-24s24%2010.7%2024%2024v64h64c13.3%200%2024%2010.7%2024%2024s-10.7%2024-24%2024H280v64c0%2013.3-10.7%2024-24%2024s-24-10.7-24-24z%22%2F%3E%3C%2Fsvg%3E') calc(100vw / 2 - var(--left-col-width)) 5rem no-repeat;
    }

    .plus-sign {
      display: block;
    }

    .drawflow .main-path.connection-rect {
      display: block;
      stroke: black;
      stroke-width: 2;
      stroke-dasharray: 7 4;
      fill: var(--dragelement-hover-background-color);
      opacity: 0;
      //pointer-events: auto;

      &.hovered {
        opacity: .4;
        //pointer-events: all !important;
      }
    }
  }

  &.on-clipboard{
    .parent-node{
      pointer-events: none;

      &:has(.drawflow-node:not(.copy-selected)) {
        filter: brightness(90%);
        opacity: .9;
      }

      .drawflow-node.copy-selected{
        .node-container {
          --tw-ring-offset-width: 2px;
          box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--drawarea-background-color),
                      var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--node-move-main-color),
                      0 20px 13px rgb(0 0 0 / 0.05),
                      0 12px 6px rgb(0 0 0 / 0.1);
        }

        .actions{
          display: flex;

          .p-button:not(.drawflow-move-node){
            visibility: hidden;
          }
          .p-button.drawflow-move-node{
            color: var(--white);
            background-color: var(--node-move-main-color) !important;
            border-color: var(--node-move-main-color);
            cursor: copy !important;
            visibility: visible;
            pointer-events: all;
            opacity: 1 !important;
            filter: brightness(1);
          }

        }


      }
      .drawflow-node:not(.copy-selected) {
        .node-container {
          --tw-ring-offset-width: 2px;
          box-shadow: none;
        }
      }
    }

    .main-path.connection-rect {
      display: block;
      stroke: var(--node-move-main-color) !important;
      stroke-width: 2;
      stroke-dasharray: 7 4;
      fill: var(--node-move-hover-background-color) !important;
      opacity: 0;

      &:hover {
        opacity: .4;
        cursor: cell;
      }
    }

  }

}

.node__settings {
  &.p-dialog {
    .p-dialog-header,
    .p-dialog-content:last-of-type {
      border-radius: 0;
      border: none;
    }

    .p-dialog-content {
      padding: 0
    }

    .p-dialog-header {
      padding: 1rem 1.5rem;
      background-color: var(--drawarea-background-color);
      /*color: var(--white);*/
    }
  }

  &--entrypoint-change {
    padding: 1rem 1.5rem;
  }

  &--delay-alert{
    margin: 1.5rem;
    .p-message{
      margin: 0;
    }
  }

  &--body {

    padding: 1.5rem;

    /* Works on Firefox */
    scrollbar-width: thin;
    scrollbar-color: var(--node-settings-scrollbar-handle-color) var(--node-settings-scrollbar-background-color);

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--node-settings-scrollbar-handle-color);
      border-radius: 20px;
      border: 3px solid var(--node-settings-scrollbar-background-color);
    }

    p-calendar{
      button, button:enabled:hover{
        background: #fff;
        color: #999;
        border-left: none;
        border-color: #d4d4d8;
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      }
    }

  }

  .sidebar-template-placeholder{
    border-color: #d4d4d8;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    .canspam-error .p-message{
      margin: 0;
      border-radius: 0;
    }
  }

  .sidebar-template-container:hover{
    > div.sidebar-template-edit{
      opacity: 0;
    }
  }

  &--footer {
    background-color: var(--drawarea-background-color);
    padding: 1rem 1.5rem;
  }

}

#flows-node-shadow.drawflow-node {
  width: 236px !important;
  //margin: 2px;
  * {
    pointer-events: none;
    user-select: none;
  }

  .actions, .inputs, .outputs {
    display: none !important;
  }
}
