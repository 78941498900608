.contacts__table{
  .p-datatable-tbody > tr{

    .contact__details--link{
      opacity: 0;
    }

    &:hover {
      background-color: var(--gray-50);

      .contact__details--link{
        opacity: 1;
      }
    }
  }
}
